<template>
  <div id="app-shell">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data: function () {
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
